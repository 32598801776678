// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../../resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.step-2-wrapper[data-v-20abfd88]{width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:.7rem}.step-2-wrapper img[data-v-20abfd88]{width:30%;margin-bottom:15%;margin-right:8%}.step-2-wrapper .progress-bar[data-v-20abfd88]{text-align:center;margin:.2rem auto;font-size:.26rem;font-weight:700;color:rgba(0,0,0,.85);padding-bottom:.2rem}.step-2-wrapper[data-v-20abfd88] .ivu-progress{width:100%;padding-right:none!important}.step-2-wrapper[data-v-20abfd88] .ivu-progress .ivu-progress-text-inner{margin-top:.15rem;color:#2b4d8f}.step-2-wrapper[data-v-20abfd88] .ivu-progress-text-inner{position:absolute;font-size:.16rem;top:.3rem;left:44.5%;color:#1fb6ac}", ""]);
// Exports
module.exports = exports;
